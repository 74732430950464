import React from 'react';

import { PromoType, getPromoItems } from '../../../../sharedModules/getPromoItems';
import getButtonText from '../../../modules/getButtonText';
import type { Deal } from '../../../types/Deal';
import getPriceType from '../../../utils/getPriceType';
import { Consumer } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import { HotDealFlag } from '../../GenericComponents/HotDealFlag';
import { Promos } from '../../GenericComponents/Promos/loadable';
import { AmazonPrimeShipping } from '../../GenericComponents/Shipping';

import { SearchIcon } from './SearchIcon';
import styles from './styles/price-extra-deal.css';

interface PriceExtraDealProps {
  deal: Deal;
}

const PriceExtraDeal: React.FC<PriceExtraDealProps> = ({ deal }) => (
  <Consumer>
    {(value): JSX.Element | null => {
      // eslint-disable-next-line max-len
      const {
        inFocus,
        showHotDeal,
        translate,
        showShippingPosition,
        genericSharedComponents: { AffiliateLink, DisplayPrice },
        showShipping,
      } = value;

      if (deal && deal.offer && deal.merchant) {
        const promoItems = getPromoItems(deal, PromoType.FLAGS, false);
        return (
          <div className={styles.container}>
            <div className={styles['promos-prime-container']}>
              {showShippingPosition === 'top' &&
                showShipping &&
                deal?.shipping?.url &&
                deal?.shipping?.prime && (
                  <AmazonPrimeShipping
                    {...{
                      deal,
                      showLink: false,
                      className: 'legacy-price-extra-deal',
                      width: 35,
                      height: 16,
                    }}
                  />
                )}
              {promoItems.length > 0 && (
                <Promos items={promoItems} className="price-widget-extra-deal" />
              )}
            </div>
            {deal.product_type !== 200 ? (
              <DisplayPrice
                {...{
                  deal,
                  className: 'price-widget-extra-deal',
                  type: 'wasPrice',
                }}
              />
            ) : null}
            <div className={styles.main}>
              {deal.product_type !== 200 ? (
                <>
                  <DisplayPrice
                    {...{
                      deal,
                      className: 'price-widget-full-price-extra-deal',
                      type: getPriceType(deal),
                    }}
                  />
                  {Boolean(showHotDeal) && inFocus && (
                    <HotDealFlag
                      {...{
                        deal,
                        className: 'price-extra-deal-full-price',
                      }}
                    />
                  )}
                </>
              ) : null}
              <AffiliateLink
                {...{
                  deal,
                  className: 'styled',
                }}
              >
                {getButtonText(deal, true, translate)}
              </AffiliateLink>
              {(showShippingPosition === 'next' || showShippingPosition === 'inside') &&
                showShipping &&
                deal?.shipping?.url &&
                deal?.shipping?.prime && (
                  <AmazonPrimeShipping
                    {...{
                      deal,
                      showLink: false,
                      className: 'legacy-price-extradeal-button',
                      width: 35,
                      height: 15,
                    }}
                  />
                )}
              {deal.product_type === 200 && <SearchIcon />}
            </div>
          </div>
        );
      }
      return null;
    }}
  </Consumer>
);

export default PriceExtraDeal;
