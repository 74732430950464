import React from 'react';

import { PromoType, getPromoItems } from '../../../../sharedModules/getPromoItems';
import getButtonText from '../../../modules/getButtonText';
import type { Deal } from '../../../types/Deal';
import getPriceType from '../../../utils/getPriceType';
import { Consumer } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import { HotDealFlag } from '../../GenericComponents/HotDealFlag';
import { Promos } from '../../GenericComponents/Promos/loadable';
import { AmazonPrimeShipping } from '../../GenericComponents/Shipping';

import styles from './styles/price-deal.css';

interface PriceDealProps {
  deal: Deal | null;
}

const PriceDeal: React.FC<PriceDealProps> = ({ deal }) => (
  <Consumer>
    {(value): JSX.Element | null => {
      const {
        inFocus,
        showHotDeal,
        translate,
        showShippingPosition,
        showPrice,
        genericSharedComponents: { AffiliateLink, DisplayPrice },
        showShipping,
      } = value;

      if (deal && deal.offer && deal.merchant) {
        const className = deal.product_type === 200 ? 'similar' : 'container';
        const promoItems = getPromoItems(deal, PromoType.FLAGS, false);

        return (
          <div className={styles[className]}>
            <div
              className={
                styles[
                  showPrice === 'availableFrom'
                    ? 'available-from-margins'
                    : 'promos-prime-container'
                ]
              }
            >
              {showShippingPosition === 'top' &&
                showShipping &&
                deal?.shipping?.url &&
                deal?.shipping?.prime && (
                  <AmazonPrimeShipping
                    {...{
                      deal,
                      showLink: false,
                      className: 'legacy-price-deal',
                      width: 35,
                      height: 16,
                    }}
                  />
                )}
              {promoItems.length > 0 && <Promos items={promoItems} className="price-widget-deal" />}
            </div>
            <DisplayPrice
              {...{
                deal,
                className: 'price-widget-deal',
                type: 'wasPrice',
              }}
            />
            <div className={styles.main}>
              <DisplayPrice
                {...{
                  deal,
                  className: 'price-widget-deal',
                  type: getPriceType(deal),
                }}
              />
              {Boolean(showHotDeal) && inFocus && (
                <HotDealFlag
                  {...{
                    deal,
                    className: 'price-widget-deal',
                  }}
                />
              )}
              <AffiliateLink
                {...{
                  deal,
                  className: 'price-widget-button',
                }}
              >
                {getButtonText(deal, true, translate)}
                {showShippingPosition === 'inside' &&
                  showShipping &&
                  deal?.shipping?.url &&
                  deal?.shipping?.prime && (
                    <AmazonPrimeShipping
                      {...{
                        deal,
                        showLink: false,
                        className: 'pricedeal-button',
                        height: 15,
                      }}
                    />
                  )}
              </AffiliateLink>
              {showShippingPosition === 'next' &&
                showShipping &&
                deal?.shipping?.url &&
                deal?.shipping?.prime && (
                  <AmazonPrimeShipping
                    {...{
                      deal,
                      showLink: false,
                      className: 'legacy-price-deal-button',
                      width: 35,
                      height: 15,
                    }}
                  />
                )}
            </div>
          </div>
        );
      }
      return null;
    }}
  </Consumer>
);

export default PriceDeal;
